// creer l'instanciation de l'objet XHR qu'inporte le navigateur
function getXMLHttpRequest() {
    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                "Msxml2.XMLHTTP.6.0",
                "Msxml2.XMLHTTP.3.0",
                "Msxml2.XMLHTTP",
                "Microsoft.XMLHTTP",
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch (error) {
                }
            }
        } else {
            xhr = new XMLHttpRequest();
        }
    } else {
        return null;
    }
    return xhr;
}

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseText;
    else return false;
}

function ajax_file_post(url, params) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader(
        "Content-type",
        "application/x-www-form-urlencoded"
    );
    xhr_object.send(params);

    if (xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseXML;
    else return false;
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                parentPath +
                'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length
                ? $("#" + couleurs_id).val()
                : "none";
            var tailles = $("#" + tailles_id).length
                ? $("#" + tailles_id).val()
                : "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            parentPath +
            "ajax_liste_qte.php?idprod=" +
            idProduit +
            "&idcouleur=" +
            couleurs +
            "&idtaille=" +
            tailles +
            "&produit_lot_nb=" +
            $("#produit_lot_nb").val() +
            "&suff=" +
            (suff || "")
        );


        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            setTimeout(function () {
                var qte_stock = $('#product_stock_counter').val();

                if (qte_stock > 0) {
                    $(".eclat_product_epuise_fiche_produit").hide();
                    $(".eclat_product_nouveaute_fiche_produit").hide();
                    $(".eclatProd").show().removeClass('cache');
                    $("#bloc_add_basket").show();
                    $("#btn_add_cart").attr('disabled', false);
                    $("#bloc_add_alert").hide();
                    $(".stock_info.yes").show();
                    $(".stock_info.no").hide();
                    $(".productQuantityFieldset").removeClass("without_stock");
                    $(".qte_select_wrapper").removeClass("without_stock");
                    $(".chez_vous_entre").show();
                } else {
                    $(".eclat_product_epuise_fiche_produit").removeClass('cache').show();
                    $(".eclat_product_nouveaute_fiche_produit").hide();
                    $(".eclatProd").hide();
                    $("#bloc_add_basket").hide();
                    $(".wrapper_add_btn").hide();
                    $("#bloc_add_alert").show();
                    $(".stock_info.yes").hide();
                    $(".stock_info.no").show();
                    $(".productQuantityFieldset").addClass("without_stock");
                    $(".qte_select_wrapper").addClass("without_stock");
                    $(".chez_vous_entre").hide();
                }
            }, 250);
        }
    }
}

/**
 * Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php)
 */
function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr('id'),
        mId = id.substring(id.indexOf('_') + 1),
        messageContainer = $('.message_account_container'),
        parentElem = elem.parents('tr');

    if (messageContainer.hasClass('appear')) {
        messageContainer.removeClass('appear');
    }

    if ((elem).hasClass('actif')) {
        $('.message_detail').slideUp();
        (elem).removeClass('actif');
        (elem).text(msg_open);
    } else {
        $('.message_detail').slideUp();
        $('.btn_ouvrir_message').not(elem).removeClass('actif');
        $('.btn_ouvrir_message').not(elem).text(msg_open);
        $.ajax({
            type: 'post',
            data: {mId: mId, type: 1},
            url: path_relative_root + create_link('message_detail'),
            success: function (response) {
                $('#msg_' + mId).html(response);
                $('#msg_' + mId).slideDown();
            }
        });
        (elem).addClass('actif');
        (elem).text(msg_close);
        parentElem.next().addClass('appear');
    }
}

function ajax_add_coupon(parentPath) {
    var code = $('#code_header').val();

    $("#form_advantage .w-submit-button.w-loader").addClass("loading");

    if (code.trim() == '') {
        $("#form_advantage .w-submit-button.w-loader").removeClass("loading");
        return true;
    }

    $.ajax({
        url: parentPath + create_link('ajax_add_coupon_from_header'),
        type: 'post',
        data: {
            codePromo: code,
            act: 'promo'
        },
        success: function (res) {
            setTimeout(function () {
                $("#form_advantage .w-submit-button.w-loader").removeClass("loading");
            }, 100);

            if (res.success) {
                document.location.reload();
            } else {
                $('#code_header').addClass('w-has-error');
                $('.errorTxt').show();
                if (res.error) {
                    $('.errorTxt').html(res.error);
                }
            }
        }
    });
}

function ajaxDeconnect(path_relative_root) {
    var provenance = window.location.pathname,
        cpt_client = new RegExp(
            /(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/
        ),
        response;

    // Get the current page's name
    provenance = provenance.substr(
        provenance.lastIndexOf("/"),
        provenance.lastIndexOf(".")
    );

    // This actually disconnects the client
    response = ajax_file(path_relative_root + create_link('ajax_deconnect'));

    // If there was an error
    if (!response) alert("Erreur lors de la déconnexion.");
    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href =
            path_relative_root + create_link("connexion_login");
    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

var bloc_prod_xhr;

//Uilisée pour afficher les produits
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollTop, fromScrollBottom, fromPagination = false) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        output = {};

    // Add for infinite scroll up
    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
    }
    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky', fromPagination);

    if (page != undefined && page != 'all') {
        if (page != 0) {
            output.page = $('#list_item .item:not(".push")').length;
        }
    }

    $('#trigger_filtre .initFilters').show();

    if (page === 'all') {
        /** LEGACY **/
        var hash;
        if ((hash = location.hash && location.hash.match(/i=[0-9]+/g)[0]) || page_value > 0) {
            var marginTop;
            if ($('.wrapper_bandeau')) {
                margintop = $('.wrapper_bandeau').height() + 20
            } else {
                margintop = $('#site_head_wrap').height() + 20
            }
            var $elem = hash ? $('#i=' + hash.replace('=', '')) : $list_item,
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {

                $('#contents_wrapper').animate({
                    scrollTop: top
                }, 0.8);
            }, 0);
        }

        /** END LEGACY **/
    } else {
        if (bloc_prod_xhr) {
            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + create_link('ajax_reload_products'),
            type: 'get',
            data: output,
            success: function (res) {
                var old_elm = document.getElementById('list_item'),
                    content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {
                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');
                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                // This block of code updates the SEO-related link elements in the HTML document.
                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + canonical);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if (content !== '') {
                    $('.load_wrapper .pagerNav.end_pagi').show();

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;

                    paginate(page + 1);
                    old_elm.insertAdjacentHTML('beforeend', content);

                    //validate_filter
                    $("#validate_filter").html(Translator.translate("see_results") + " (" + itm_length + ")");

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                    if (typeof lazyloadImage === 'function') {
                        lazyloadImage();
                    }

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) {
                        }
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) {
                        }
                    }

                    getwishlistrayon();

                    /** END LEGACY **/
                } else {
                    $('.load_wrapper .pagerNav.end_pagi').hide();

                    content = '<div class="no_results_wrapper">' +
                        '<div class="search_details_wrapper">' +
                        '<div class="no_result_block">' +
                        Translator.translate('no_result_txt') +
                        '</div>' +
                        '</div>' +
                        '</div>';
                    old_elm.innerHTML = content;
                }
            },
            complete: function () {
                let maxItems = document.getElementById('totalElems').value;
                let productLoaded = $('#list_item .item:not(".push")').length;

                if (!fromScrollBottom && !fromScrollTop) {
                    setTimeout(function () {
                        $("html, body").animate({
                            scrollTop: ($(window).scrollTop())
                        }, 200);
                    }, 250);
                }

                $('.loader_scroll').removeClass('loading');

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                }

                if ($("body.category").length) {
                    addClickTrigger(page+1);
                    updateLoadedGauge();
                }

                if (productLoaded >= maxItems || productLoaded == 0) {
                    $('.pagerNav.end_pagi .loader_scroll').hide(); // Hide "See all" button
                } else {
                    $('.pagerNav.end_pagi .loader_scroll').show();
                }

                $('.txt_bandeau label').html('(' + maxItems + ' ' + Translator.translate('2_product') + ' )');
            }
        });
    }
}

var tempo_reset = false;

function initAllFilters() {
    // Get current URL query string
    var url = new URL(window.location.href);
    var urlParams = new URLSearchParams(url.search);
    
    // Check for 'order=desc' or 'order=asc' and remove if present
    if (urlParams.has('order')) {
        var orderValue = urlParams.get('order');
        
        if (orderValue === 'asc' || orderValue === 'desc') {
            urlParams.delete('order');

            // Update the URL
            var newParams = urlParams.toString();
            var newUrl = url.pathname;

            if (newParams != "") {
                newUrl += '?' + newParams;
            }

            window.history.replaceState(null, '', newUrl);

            // Reload the page after the parameter is removed
            window.location.reload();

            return; // Stop further code execution to reload the page
        }
    }

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();

                // Remove is_checked classes
                if ($(elm).hasClass('is_checked')) {
                    $(elm).removeClass('is_checked');
                }
                // Remove active filters counters
                if ($("#trigger_filtre .filtre_elem .count_filters").length) {
                    $("#trigger_filtre .filtre_elem .count_filters").remove();
                }
            }
        }
    });

    $('a.tri_price.actif').removeClass('actif');
    $(".filtre_elem").each(function (index) {
        $(this).removeClass("is_checked");
    });
    $(".filters_dropdown_content input[type='checkbox']").each(function (index) {
        $(this).prop("checked", false);
    });

    $('#trigger_filtre .initFilters').hide();
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur,
    qte,
    productBatchId
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = $("#couleurProd" + i);
    var tailles = $("#tailleProd" + i);
    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == "") {
        $("#sizeerror").slideDown();

        return false;
    } else if (couleurs && couleurs.val() == "") {
        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != "none") idCouleur = couleurs.val();

        if (tailles && tailles.val() != "none") idTaille = tailles.val();

        var array_response = [];
        var uri = path_web + "ajax_upd_panier.php"
            + "?prixu=" + prixU
            + "&idprod=" + idProduit
            + "&idcouleur=" + idCouleur
            + "&idtaille=" + idTaille
            + "&qte=" + qte
            + "&panierId=" + paniervirtuelId;
        if (productBatchId && productBatchId != idProduit && typeof productBatchId !== "undefined") {
            uri += '&batchId=' + productBatchId;
        }
        var response = ajax_file(uri);

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (typeof titreObjet == "string" && titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    designation +=
                        " - " + Translator.translate('size') +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                            ].text;
                } else if (lastTaille != "") {
                    designation += " - " + Translator.translate('size') + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation +=
                        " - " +
                        $('[name="' + idElCouleur + '"]:checked')
                            .get(0)
                            .nextElementSibling.title.trim();
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(translate("product_no_stock_2"));
                return false;
            }
        }

        return array_response;
    }
}

function ajax_reset_coupon(parentPath) {
    $.ajax({
        url: parentPath + create_link('ajax_reset_coupon_from_header.php'),
        type: 'post',
        data: {
            act: 'delcp'
        },
        success: function (res) {
            if (res.success) {
                document.location.reload();
            } else {
                alert(res.error);
            }
        }
    });
}

/** Panier **/

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {
    $.ajax({
        type: "post",
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte
        },
        url: path_relative_root + create_link('ajax_removeBasketProd'),
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            $("#cart_top").html(res.datas.html);
            newshowmodal();
            $("#cart_list.cart_product_listing").overlayScrollbars({
                scrollbars: {
                    autoHide: "leave",
                    autoHideDelay: 0,
                }
            });
        },
    });
}

function loaderBatch(btn, id_elt, mobile, show) {
    // Loader
    if (show) {
        btn.parent('.form_submit').find('.loader').addClass('loading');
    } else {
        btn.parent('.form_submit').find('.loader').removeClass('loading');
    }
}

function udpdateAchatExpressV2BatchCart(btn, response_basket) {
    $("#cart_top").html(response_basket);
    setTimeout(function () {
        $("#cart_list.cart_product_listing").overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
        newshowmodal();
        btn.parent('.form_submit').removeClass('loading');
        btn.parent('.form_submit').find('.loader').removeClass('loading');
    }, 2000);
}

/****fin Panier****/

function renewOrder(elem, orderId) {
    const basketFilled = addOrderToBasket(elem, orderId);

    if (basketFilled === false) {
        return;
    }

    basketFilled.then(function ({ oneProductAdded, oneProductOutOfStock, emptyBasket }) {
        if(emptyBasket || (!oneProductAdded && oneProductOutOfStock)) {
            $('.order_error').remove();
            $('tr[data-order=' + orderId + '] td.num_command').append('<span class="order_error">' + Translator.translate('renew_order_no_product_available') + '</span>');
        }

        if (oneProductAdded){
            if (oneProductOutOfStock) {
                $('.order_error').remove();
                $('tr[data-order=' + orderId + '] td.num_command').append('<span class="order_error">' + Translator.translate('renew_order_out_of_stock') + '</span>');
            }
            
            var response_basket = ajax_file('ajax_show_basket.php');

            $('#cart_top').html(response_basket);

            var count_bask = $('#cart_top .cart').attr('data-cart');

            if (count_bask > 2) {
                $("#cart_list.cart_product_listing").overlayScrollbars({
                    scrollbars: {
                        autoHide: "leave",
                        autoHideDelay: 0,
                    }
                });
            }

            newshowmodal();
        }
    })
}
